.header {
  /* background: url("../../assets/images/landingpage.jpg") no-repeat;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center; */
  margin-top: 30px;
}

.header .carousel .thumbs-wrapper {
  display: none;
}

.header .textHeader {
  position: absolute;
  top: 15%;
  left: 55%;
  margin-left: -45%;
  width: 90%;
  border-radius: 10px;
  color: #fff;
  padding: 10px;
  font-size: 12px;
  text-align: left;
}

.header .textHeader h1 {
  font-weight: 700;
  font-family: "Poppins-SemiBold";
  font-size: 4vw;
}

.header .textHeader .p1 {
  font-weight: 500;
  color: #093b67;
  text-transform: uppercase;
  font-family: "Poppins-Bold";
  font-size: 1vw;
  display: flex;
  align-items: center;
}

.header .carousel .carousel-status {
  display: none;
}

.header .textHeader .p1 hr.vertical {
  border: 0;
  margin: 0 10px 0 0;
  border-left: 5px solid #ef4563;
  height: 40px !important;
  float: left;
  opacity: 1;
}

.header .buttoncss {
  height: 40px;
  width: 150px;
  font-size: 12px;
}

.header .textHeader .p2 {
  font-weight: 500;
  font-family: "Poppins-SemiBold";
  font-size: 1vw;
}

.header .textHeader .p3 {
  font-family: "Poppins-Light";
  width: 350px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 1vw;
}

@media only screen and (max-width: 768px) {
  .header .textHeader {
    top: 0;
  }
  .header .textHeader .p1 hr.vertical {
    height: 20px !important;
  }
  .header .textHeader .p1 {
    font-size: 7px;
    margin-bottom: 3px;
  }
  .header .textHeader .p2 {
    font-size: 9px;
    margin-bottom: 5px;
  }
  .header .textHeader h1 {
    font-size: 13px;
  }
  .header .textHeader .p3 {
    margin-top: 10px;
    margin-bottom: 2px;
    font-size: 8px;
    width: initial;
  }
  .header .buttoncss {
    height: 20px;
    width: 80px;
    font-size: 9px;
  }
}
