.button {
  font-weight: bold;
  font-size: 18px;
  color: #fff;
  width: 240px;
  border-radius: 44px;
  height: 48px;
  outline: 0;
  border: 0;
}

.regular-btn {
  background-color: #ef4565;
}

.search-wrapper {
  position: relative;
  z-index: 2;
}

.search-wrapper .search-btn {
  width: 30px;
  position: absolute;
  height: 30px;
  right: -15px;
  border-radius: 100%;
  background: #ef4565;
  color: #fff;
  border: 0;
  top: 13px;
}
