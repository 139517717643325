body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.red {
  color: #ef4565;
}

.selectClass2 {
  font-size: 14px;
  margin-right: 0;
  border-radius: 19px;
  line-height: 18px;
  display: flex;
  text-align: left;
  color: #ef4565;
  text-transform: uppercase;
  font-family: "Poppins-SemiBold", sans-serif;
}

.locIcon {
  font-size: 18px;
  margin-right: 10px;
  color: #adb5bd9c;
}

.card-body {
  padding: 1rem 2.5rem;
}

.info-select {
  text-transform: capitalize;
}

.loader {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  animation: bg 1s;
}

main {
  margin-bottom: 40px;
}
