.inputContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.input-wrapper {
  width: 100%;
  position: relative;
}

.input {
  background-color: #f5f5f5;
  border: 1px solid #094067;
  box-sizing: border-box;
  border-radius: 36px;
  font-weight: normal;
  font-size: 16px;
  margin: 8px 0;
  height: 40px;
  padding: 0 20px;
  color: #094067;
}

::placeholder {
  color: #c4c4c4;
  font-size: 14px;
}

.webkit {
  width: -webkit-fill-available;
}

.small {
  width: 250px;
}

.large {
  width: 400px;
}

.label {
  color: #9a9a9a;
}

.input:focus,
.input.error:focus {
  border-color: #094067;
  outline: none;
}

@media only screen and (max-width: 768px) {
  .large {
    width: auto;
  }
}
